<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('salesInstallments.form.CreateAPremium')"
          :description="$t('salesInstallments.form.FromHereYouCanCreateANewSalesPremium')"
          v-if="$route.name == 'salesInstallmentsCreate'"
        />
        <addHeader
          :title="$t('salesInstallments.form.PremiumAdjustment')"
          :description="$t('salesInstallments.form.FromHereYouCanModifyYourPurchaseCheck')"
          v-if="$route.name == 'salesInstallmentsEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('salesInstallments.form.NumberingTheBatch')"
            :group="$t('salesInstallments.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
            :hasErorr="errors.invoice_group"
            :error="$t('salesInstallments.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'salesInstallmentsCreate'"
          />
          <formInput
            :title="$t('salesInstallments.form.PaymentCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'salesInstallmentsEdit'"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <selectInput
            :title="$t('salesInstallments.form.PremiumType')"
            v-model="item.client_type"
            :disabled="$route.name == 'salesInstallmentsEdit' ? true : false"
            :values="[
              { name: $t('salesInstallments.form.CustomerPremium'), value: 0 },
              { name: $t('salesInstallments.form.PremiumSupplier'), value: 1 },
              { name: $t('salesInstallments.form.PremiumPartner'), value: 2 },
            ]"
          />

          <searchGroupInput
            v-if="item.client_type == 0"
            :type="'customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            :inputs="$option.salesInstallments_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 1"
            :type="'supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            :inputs="$option.salesInstallments_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 2"
            :type="'partner'"
            :values="$database.partners.filter((el) => el.stat == 1)"
            :inputs="$option.salesInstallments_customer_data"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('salesInstallments.form.ConstructionDate')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('salesInstallments.form.choosePaymentDate')"
                  :button-now-translation="$t('salesInstallments.form.Now')"
                  v-model="item.created_date"
                  :disabled="
                    $user.admin ||
                    $user.role.sales_cheques_edit_item
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'"> {{$t('salesInstallments.form.paymentDate')}} </label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('salesInstallments.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('salesInstallments.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <searchGroupInput
          :type="'project'"
          :values="$database.projects"
          :inputs="[{ 'title':$t('partnersSettlements.form.Project'),'show': 'name'}]"
          v-if="$site.projects_allow"
        />


          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('salesInstallments.form.ProjectOutput')"
            :group="$t('salesInstallments.form.ProjectOutputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter(el => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />


          <dvider
            :title="$t('salesInstallments.form.accountsInformation')"
            :description="$t('salesInstallments.form.AccountInformationDetails')"
          />

          <monyInput
            :title="$t('salesInstallments.form.PremiumAmount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <formInput
            :type="'number'"
            :title="$t('salesInstallments.form.benefitAverage')"
            v-model="item.benefit_rate"
            :hasErorr="errors.rate"
            :error="$t('allerts.theTaxRateShouldBeFromTo')"
          />

          <monyInput
            :title="$t('salesInstallments.form.benefit')"
            :disabled="true"
             v-model="item.benefit"
            :hasErorr="errors.benefit"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <monyInput
            :title="$t('salesInstallments.form.total')"
            :disabled="true"
            v-model="item.total"
            :hasErorr="errors.total"
            :error="$t('allerts.totalMustBeGreaterThan')"
          />

          <formInput
            :type="'number'"
            :title="$t('salesInstallments.form.installmentsCount')"
            v-model="item.installments_count"
            :hasErorr="errors.installments_count"
            :error="$t('allerts.installmentsCountMustBeGreaterThan')"
          />

          <formInput
            :type="'number'"
            :title="$t('salesInstallments.form.periodBetweenInstallments')"
            v-model="item.period_between_installments"
            :hasErorr="errors.period_between_installments"
            :error="$t('allerts.periodBetweenInstallmentsMustBeGreaterThan')"
          />

          <connectionInput
            :title="$t('salesInstallments.form.PaymentMethod')"
            :group="$t('salesInstallments.form.PaymentRoute')"
            v-model="item.payment_method_id"
            :hasAdd="true"
            :open="'addPaymentMethod'"
            :values="$database.paymentMethods"
            :value="item.payment_fee_id"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
          />

          <div class="form-group row col-md-6">
            <label class="col-md-4">توليد اقساط</label>
              <div class="col-md-7">
                <a class="btn btn-block btn-primary" @click="generateInstallments()"> توليد الأقساط</a>
              </div>
          </div>
          <dvider
            :title="$t('salesInstallments.form.salesInstallments')"
            :description="$t('salesInstallments.form.salesInstallmentsForInvoice')"
          />
          <salesInstallmentsTable class="col-12" />

          <div class="col-6"></div>

          <dvider
            :title="$t('salesInstallments.form.PaymentNotes')"
            :description="$t('salesInstallments.form.PaymentNotesDetails')"
          />

          <formTextarea
            :title="$t('salesInstallments.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('salesInstallments.form.note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesInstallmentsCreate' && !$parent.stopEdit"
          >
          {{$t('salesInstallments.form.CreateAPremium')}}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesInstallmentsEdit' && !$parent.stopEdit"
          >
          {{$t('salesInstallments.form.PremiumAdjustment')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
    <addPaymentFee v-if="model == 'addPaymentFee'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";
import addPaymentFee from "../elements/popupForms/addPaymentFee.vue";

import salesInstallmentsTable from "../elements/add/form/invoice/salesInstallmentsTable.vue";

import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/salesInstallments",
      item: {
        invoice_group: this.$option.sales_installment_invoice_group_id,
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        customer: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        supplier: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        client_type: 0,
        invoice_id: 0,
        payment_method_id: 0,
        payment_fee_id: [],
        bank_tax: 0,
        safe_id: 0,
        date: "",
        created_date: "",
        benefit_rate: "",
        benefit: "",
        total: 0,
        salesInstallmentItems:[]
      },
      selectedPaymentFees: [],
      model: false,
      errors: {
        invoice_group: false,
        customer_id: false,
        cost: false,
        benefit: false,
        safe_id: false,
        total: false,
        installments_count: false,
        period_between_installments: false
      },
      print: 1,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "customers",
      "suppliers",
      "projects",
      "projectItems",
      "paymentMethods",
      "paymentFees",
    ]);

    if (this.$database.safes.length == 1) {
      this.item.safe_id = this.$database.safes[0].id;
    }
    this.addSalesInstallment();

    if (this.$route.name == "salesInstallmentsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }

    if (this.$route.name == "salesInstallmentsCreate") {
      this.item.created_date = this.$nowDate()
      this.item.date = this.$nowDate(5)
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          if (Array.isArray(JSON.parse(this.item.payment_fee_id))) {
            JSON.parse(this.item.payment_fee_id).forEach((id) => {
              var paymentFee = this.$database.paymentFees.find(
                (el) => el.id == id
              );
              this.selectedPaymentFees.push(paymentFee);
            });
          }
          this.item.date         = this.$dateFormat(this.item.date, true)
          this.item.created_date = this.$dateFormat(this.item.created_date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer_id === "undefined" ||
          this.item.customer_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier_id === "undefined" ||
          this.item.supplier_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 2 &&
        (typeof this.item.partner.name === "undefined" ||
          this.item.partner.name == "") &&
        this.$route.name == "salesInstallmentsCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost < 1
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "salesInstallmentsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date);
        this.item.created_date = this.$fixDate(this.item.created_date);
        if (this.$route.name == "salesInstallmentsCreate") {
          this.$parent.aletText = this.$t('allerts.salesInstallmentsuccessfullyAdde');
          this.$parent.alertType = "success";
           this.$addToLocalDatabase(this.item, this.path.substring(1));
          if (this.item.client_type == 0) {
            this.item.old_balance = this.item.customer.balance;
          } else {
            this.item.old_balance = this.item.supplier.balance;
          }
          this.item.new_balance = parseFloat(this.item.old_balance) + parseFloat(this.item.cost);          

          // change customer balance
          if (this.item.client_type == 0) {
            var customer = this.$database.customers.find((el) => el.id == this.item.customer_id);
            if (customer) {
              customer.balance = this.item.new_balance;
            }
          } else {
            var supplier = this.$database.suppliers.find((el) => el.id == this.item.supplier_id);
            if (supplier) {
              supplier.balance = this.item.new_balance;
            }
          }

          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "salesInstallmentsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "safe error") {
                this.$parent.aletText = "الموظف غير مربوط بخزينة";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.$t('allerts.salesPaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    changeTotal() {
      var cost = this.item.cost ? this.item.cost : 0
      this.item.benefit = cost * this.item.benefit_rate / 100
      this.item.total = (cost - 0) + (this.item.benefit - 0)

    },
    addSalesInstallment() {
      this.item.salesInstallmentItems.push({
        date: "",
        cost: "",
      });
    },
    generateInstallments () {
      var error = 0;
      if (
        typeof this.item.total === "undefined" ||
          this.item.total <= 0
      ) {
        error = 1;
        this.errors.total = true;
      } else {
        this.errors.total = false;
      }
    if (
        typeof this.item.installments_count === "undefined" ||
          this.item.installments_count <= 0
      ) {
        error = 1;
        this.errors.installments_count = true;
      } else {
        this.errors.installments_count = false;
      }
    if (
        typeof this.item.period_between_installments === "undefined" ||
          this.item.period_between_installments <= 0
      ) {
        error = 1;
        this.errors.period_between_installments = true;
      } else {
        this.errors.period_between_installments = false;
      }
      if (error == 0) {
        var costPerItem = this.item.total / this.item.installments_count
        this.item.salesInstallmentItems = []
        
        var  startDate = new Date(this.item.date.slice(0, 10));
        var hours = startDate.getHours();
        var minutes = startDate.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        for (var i = 0;i < this.item.installments_count; i++){
          if (i != 0) {
            startDate.setDate(startDate.getDate() + (this.item.period_between_installments - 0));
          }
          var date = startDate.toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0,10) + " " + hours + ":" + minutes + " " + ampm;
          this.item.salesInstallmentItems.push({
            date: date,
            total: costPerItem,
          });
        }
      }
    }
  },
  watch: {
    selectedPaymentFees: function (val) {
      this.item.payment_fee_id = val.map((el) => el.id);
    },
    'item.cost': function () {
      this.changeTotal()
    },
    'item.benefit_rate': function () {
      this.changeTotal()
    }
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    addPaymentFee,
    loading,
    salesInstallmentsTable,
    searchGroupInput,
    subFileInput,

    VueCtkDateTimePicker,
  },
};
</script>
